<template>
    <div class="container">
        <content-title :nav="nav"></content-title>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="状态">
                <el-select v-model="status" placeholder="审核状态">
                    <el-option label="待审核" value="0"></el-option>
                    <el-option label="同意" value="1"></el-option>
                    <el-option label="拒绝" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
        </el-form>
        <auto-table :DataList="Data" :option="option" :total="total">
            <el-table-column label="状态">
                <template slot-scope="{ row }">
                  <p style="color: gray;" v-if="row.status ===0 ">待审核</p>
                  <p style="color: green;" v-if="row.status ===1 ">通过</p>
                  <p style="color: red;" v-if="row.status ===2 ">拒绝</p>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{ row }">
                    <el-button size="small" v-if="row.status ===0 " type="success" @click="openDia(row, 'agree')">同意</el-button>
                    <el-button size="small" v-if="row.status ===0 " type="danger" @click="openDia(row, 'refuse')">拒绝</el-button>
                </template>
            </el-table-column>
        </auto-table>

        <el-dialog :visible.sync="dialogVisible" width="30%">
            <el-form>
                <p v-if="mode == 'agree'">确定同意机构的保证金提现申请吗？</p>
                <el-form-item label="说明" v-if="mode == 'refuse'">
                    <el-input type="textarea" :rows="4" v-model="remark" placeholder="拒绝请填写理由"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="update">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            nav: {
                firstNav: "机构管理中心",
                secondNav: "机构保证金提现审核",
            },
            curData: {},
            Data: [],
            option: [{ name: '机构', value: 'mechanismName' }, { name: '金额', value: 'amount' }, { name: '申请时间', value: 'create_time' }],
            total: 0,
            value: '',
            remark: '',
            status: '',
            mode: '',
            currentPage:1
        };
    },

    mounted() {
        this.getList()
    },

    methods: {
        search(){
            this.currentPage = 1
            this.getList()
        },
        openDia(row, mode) {
            this.mode = mode
            this.curData = row
            this.value = row.param_value
            this.remark = row.remark
            this.dialogVisible = true
        },
        getList() {
            this.$axios({
                url: 'user/mechanism/bond/withdraw/record/queryManagerListPage',
                params: {
                    currentPage: this.currentPage,
                    pageSize: 10,
                    status:this.status || null
                }
            }).then(res => {
                this.Data = res.data.data.rows
                this.total = res.data.data.total
            })
        },
        update() {
            let status = this.mode ==='agree' ? 1 : 2
            this.$axios({
                url: 'user/mechanism/bond/withdraw/record/update',
                method: 'post',
                data: {
                   id:this.curData.id,
                    status
                }
            }).then(res => {
                this.$message(res.data.message)
                this.dialogVisible = false
                this.getList()
            })
        },
    },
};
</script>

<style lang="less" scoped></style>